import _stringMultibyte from "../internals/string-multibyte";
import _toString from "../internals/to-string";
import _internalState from "../internals/internal-state";
import _iteratorDefine from "../internals/iterator-define";
import _createIterResultObject from "../internals/create-iter-result-object";
var charAt = _stringMultibyte.charAt;
var toString = _toString;
var InternalStateModule = _internalState;
var defineIterator = _iteratorDefine;
var createIterResultObject = _createIterResultObject;
var STRING_ITERATOR = "String Iterator";
var setInternalState = InternalStateModule.set;
var getInternalState = InternalStateModule.getterFor(STRING_ITERATOR);

// `String.prototype[@@iterator]` method
// https://tc39.es/ecma262/#sec-string.prototype-@@iterator
defineIterator(String, "String", function (iterated) {
  setInternalState(this, {
    type: STRING_ITERATOR,
    string: toString(iterated),
    index: 0
  });
  // `%StringIteratorPrototype%.next` method
  // https://tc39.es/ecma262/#sec-%stringiteratorprototype%.next
}, function next() {
  var state = getInternalState(this);
  var string = state.string;
  var index = state.index;
  var point;
  if (index >= string.length) return createIterResultObject(undefined, true);
  point = charAt(string, index);
  state.index += point.length;
  return createIterResultObject(point, false);
});
export default {};